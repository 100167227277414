const padDigits = function padTo2Digits(number) {
  return number.toString().padStart(2, '0');
}

const formattingMixin = {
  methods: {
    formatNumber(number) {
      return number.toLocaleString('es-CL');
    },
    formatDate(date) {
      return [padDigits(date.getDate()), padDigits(date.getMonth()), date.getFullYear()].join('-');
    },
    padDigits,
  }
}

export default formattingMixin