import axios from 'axios';

const state = {
  auth_token: null,
  user: {
    id: null,
    username: null,
    email: null,
    username: null,
    isAdmin: null,
    availableBids: null,
  }
};

const getters = {
  getAuthToken(state) {
    return state.auth_token;
  },
  getUserId(state) {
    return state.user?.id;
  },
  getUserData(state) {
    return state.user;
  },
  isAdmin(state) {
    return state.user && state.user.isAdmin;
  },
  isLoggedIn(state) {
    const loggedOut = state.auth_token == null || state.auth_token == JSON.stringify(null);
    return !loggedOut;
  },
}

const actions = {
  registerUser({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/users`, payload)
        .then((response) => {
          commit('setUserInfo', response);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
      });
  },
  loginUser({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/users/sign_in`, payload)
        .then((response) => {
          commit('setUserInfo', response);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
      });
  },
  loginUserWithToken({ commit }, payload) {
    const config = {
      headers: {
        Authorization: payload.auth_token,
      }
    };
    new Promise((resolve, reject) => {
      axios
        .get(`/member-data`, config)
        .then((response) => {
          commit('setUserInfoFromToken', response);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  logoutUser({ commit }) {
    const config = {
      headers: {
        authorization: state.auth_token
      }
    };
    new Promise((resolve, reject) => {
      axios
        .delete(`/users/sign_out`, config)
        .then(() => {
          commit('resetUserInfo');
          resolve();
        })
        .catch((error) => {
          reject(error);
        })
    });
  }
}

const mutations = {
  setAvailableBids(state, availableBids) {
    state.user.availableBids = availableBids;
  },
  setUserInfo(state, data) {
    state.user = data.data.user;
    state.auth_token = data.headers.authorization;
    axios.defaults.headers.common['Authorization'] = data.headers.authorization;
    localStorage.setItem('auth_token', data.headers.authorization)
  },
  setUserInfoFromToken(state, data) {
    state.user = data.data.user;
    axios.defaults.headers.common['Authorization'] = localStorage.getItem("auth_token");
    state.auth_token = localStorage.getItem("auth_token");
  },
  resetUserInfo(state, data) {
    state.user = {
      id: null,
      username: null,
      email: null,
      availableBids: null,
    };
    state.auth_token = null;
    localStorage.removeItem('auth_token');
    axios.defaults.headers.common['Authorization'] = null;
  }
}

const sessionManager = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
export {
  sessionManager
}