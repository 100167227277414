<template>
  <body>
    <div class="banner">
      <div class="banner-left">
        <img src="../../../assets/images/logo/mano_blanca.png" alt="Producto" class="banner-image">
      </div>
      <div class="banner-middle">
        <div class="banner-text">
          <h1>¡Ya llegamos!</h1>
          <img src="../../../assets/images/logo/logo_azul.png" alt="Logo" class="logo-image">
          <p>La única página en donde puedes obtener productos con hasta 95% de descuento</p>
        </div>
      </div>
      <div class="banner-right">
        <div class="banner-right-content">
          <p>¡A jugar! <br> <span>Te regalamos 20 fichas para que comiences</span></p>
          <router-link to="/sign_up" class="banner-button">¡Empezar!</router-link>
        </div>
      </div>
    </div>
  </body>
  <div class="separator">
    <div class="separator-text">Revisa los productos disponibles para participar. ¡Hasta 95% de descuento en cualquier de estos!</div>
  </div>
  <div class="auctions-container">
    <div v-for="auction in displayedActiveAuctions" :key="auction.id" class="auction-item">
      <GameSummary
        :itemName="auction.item_name"
        :id="auction.id"
        :currentPrice="auction.current_price"
        :marketPrice="auction.market_price"
        :remainingTime="auction.remaining_time"
        :timeUntillStart="auction.time_untill_start"
        :wonById="auction.won_by_id"
        :wonByName="auction.current_winner"
        :image="auction.image" />
    </div>
  </div>
</template>

<script>
import GameSummary from './GameSummary.vue'

export default {
  name: 'GamesIndex',
  components: {
    GameSummary,
  },
  mounted() {
    axios.get('/auctions')
         .then(response => {
           this.auctions = response.data.reduce((map, obj) => {
             map[obj.id] = obj;
             return map;
           }, {})
         });
    this.refreshAuctionsStatuses();
  },
  data() {
    return {
      auctions: {},
    }
  },
  computed: {
    auctionsArray() {
      return Object.values(this.auctions);
    },
    displayedActiveAuctions(){
      return Object.values(this.auctions).filter(a => !a.finished);
    },
    displayedFinishedAuctions(){
      let date = new Date();
      date.setDate(date.getDate() - 1);
      return Object.values(this.auctions).filter(a => a.finished && (new Date(a.last_bid_timestamp) >= date || new Date(a.start_time) >= date))
                   .sort(this.compareAuctionsDates)
    },
    renewableAuctionIds() {
      return this.displayedActiveAuctions.map(a => a.id);
    },
  },
  methods: {
    compareAuctionsDates(auction1, auction2) {
      const date1 = auction1.last_bid_timestamp || auction1.start_time;
      const date2 = auction2.last_bid_timestamp || auction2.start_time;
      return date1 > date2 ? -1 : 1;
    },
    refreshAuctionsStatuses() {
      if (!['games', 'home'].includes(this.$route.name)) return;
      setTimeout(() => {
      axios.get('/auctions/statuses', { params: { ids: this.renewableAuctionIds  }})
           .then(response => {
             response.data.forEach(auctionInfo => {
               this.auctions[auctionInfo.id].last_bid_timestamp = auctionInfo.last_bid_timestamp || '';
               this.auctions[auctionInfo.id].won_by_id = auctionInfo.won_by_id || null;
               this.auctions[auctionInfo.id].current_price = auctionInfo.current_price;
               this.auctions[auctionInfo.id].current_winner = auctionInfo.current_winner_name;
               this.auctions[auctionInfo.id].remaining_time = auctionInfo.remaining_time;
               this.auctions[auctionInfo.id].time_untill_start = auctionInfo.time_untill_start;
             });
           });
        this.refreshAuctionsStatuses();
      }, 500)
    },
  },
}
</script>

<style scoped>
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.banner {
  display: flex;
  align-items: stretch;
  width: 100%;
  height: auto;
}

.banner-left {
  background-color: #3e00b2;
  flex-basis: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.banner-left::after {
  content: '';
  position: absolute;
  top: 0;
  right: -20px;
  width: 40px;
  height: 100%;
  background: white;
  border-radius: 50%;
}

.banner-left img {
  width: 80%;
  height: auto;
}

.banner-middle {
  background-color: white;
  flex-basis: 40%;
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.banner-text h1 {
  font-size: 2em;
  margin: 0;
}

.logo-image {
  max-width: 100%;
  height: auto;
  margin: 10px 0;
}

.banner-text p {
  font-size: 1.2em;
  margin: 10px 0;
}

.banner-right {
  background-color: white;
  color: #333;
  flex-basis: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  flex-direction: column;
}

.banner-right-content {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.banner-right-content p {
  font-size: 1.2em;
  text-align: center;
  margin: 10px 0;
}

.banner-right-content span {
  font-size: 1.2em;
  font-weight: bold;
}

.banner-button {
  background-color: #6744f1;
  color: white;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 40px;
  transition: background-color 0.3s ease;
  margin-top: 10px;
  display: inline-block;
}

.banner-button:hover {
  background-color: #fde030;
  color: black;
}

.separator {
  width: 100%;
  height: auto; /* Ajusta la altura automáticamente según el contenido */
  background-color: #6744f1; /* Color de la franja separadora */
  margin: 20px 0; /* Espacio alrededor de la franja separadora */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px; /* Añade padding para aumentar los márgenes internos */
  box-sizing: border-box;
  border-radius: 20px;
}

.separator-text {
  text-align: center;
  width: 100%;
  color: white;
}

@media (max-width: 768px) {
  .separator {
    padding: 20px 20px; /* Aumenta el padding en pantallas pequeñas */
  }
}

.auctions-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.auction-item {
  flex: 1 1 calc(25% - 20px); /* 4 items por fila con espacio */
  margin: 10px;
  box-sizing: border-box;
  min-width: 200px; /* Ajusta este valor según el tamaño deseado */
  max-width: 300px; /* Ajusta este valor según el tamaño deseado */
}

@media (max-width: 768px) {
  .banner {
    flex-direction: column;
  }

  .banner-left, .banner-middle, .banner-right {
    flex: none;
    width: 100%;
    justify-content: center;
  }

  .banner-left img, .logo-image {
    width: 30%; /* Reduce el tamaño de las imágenes aún más */
    margin: 0 auto;
  }
  .banner-left::after {
    display: none; /* Oculta el borde curvo en pantallas pequeñas */
  }

  .banner-middle, .banner-right {
    padding: 10px;
  }

  .banner-text h1 {
    font-size: 1.5em;
  }

  .logo-image {
    width: 30%;
    margin: 10px auto;
  }

  .banner-text p {
    font-size: 1em;
  }

  .banner-right-content p {
    font-size: 1em;
  }

  .banner-right-content span {
    font-size: 1.2em;
  }

  .banner-button {
    width: 80%;
    text-align: center;
  }
  .auction-item {
    flex: 1 1 100%; /* 1 item por fila en pantallas pequeñas */
  }
}
</style>