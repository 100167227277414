<template>
  <section class="account-section padding-bottom">
    <div class="container">
      <div class="account-wrapper">
        <div class="left-side">
          <div class="section-header">
            <h2 class="title">¡BIENVENIDO!</h2>
            <p>Inicia sesión para comenzar a jugar</p>
          </div>
          <div class="login-form">
            <div class="form-group mb-30">
              <label for="login-email"><i class="far fa-envelope"></i></label>
              <input v-model="email" type="text" id="login-email" placeholder="Correo electrónico">
            </div>
            <div class="form-group">
              <label for="login-pass"><i class="fas fa-lock"></i></label>
              <input v-model="password" type="password" id="login-pass" placeholder="Contraseña">
              <span class="pass-type"><i class="fas fa-eye"></i></span>
            </div>
            <div class="form-group">
              <a href="#0">¡Ups! Olvidé mi contraseña</a>
            </div>
            <div class="form-group mb-0">
              <Button
                :onClick="logIn"
                message="Iniciar Sesión"
              />
            </div>
          </div>
        </div>
        <div class="right-side cl-white">
          <div class="section-header mb-0">
            <h3 class="title mt-0">¿NUEVO POR AQUÍ?</h3>
            <p>Regístrate y obten 20 fichas de regalo</p>
            <router-link class="router-link custom-button transparent" to="/sign_up">REGISTRARME</router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--============= Account Section Ends Here =============-->

</template>
<script>
import Button from "../utils/Button.vue";
import { mapActions, mapGetters } from 'vuex'
export default {
  name: "LogIn",
  components: {
    Button,
  },
  data() {
    return {
      email: null,
      password: null,
    }
  },
  created() {
    this.redirectToLogin();
  },
  computed: {
    ...mapGetters('sessionManager', ['getAuthToken', 'getUserId', 'isLoggedIn']),
  },
  methods: {
    ...mapActions('sessionManager', ['registerUser', 'loginUser', 'logoutUser']),
    logIn() {
      this.loginUser({user: {email: this.email, password: this.password }})
          .then(() => {
            this.redirectToLogin();
          });
    },
    redirectToLogin() {
      if (this.isLoggedIn) {
        this.$router.push('/auctions')
      }
    },
  },
};
</script>

<style scoped>
h1 {
  text-align: center;
  font-weight: bold;
  margin: 5px 0px;
}
img {
  max-height: 150px;
  max-width: 100%;
}
button {
  white-space: normal;
}
</style>
