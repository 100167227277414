<template>
  <section>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-sm-10 col-md-7 col-lg-4">
          <div class="dashboard-widget mb-30 mb-lg-0">
            <div class="user">
              <div class="thumb-area">
                <div class="thumb">
                  <img src="../../../assets/images/logo/footer-logo.png" alt="logo">
                </div>
                <input type="file" id="profile-pic" class="d-none">
              </div>
              <div class="content">
                <h5 class="title"><a>{{getUserData.username}}</a></h5>
                <span class="username">{{getUserData.email}}</span>
              </div>
            </div>
            <ul class="dashboard-menu">
              <li>
                <p>Aquí puedes encontrar toda la información referida a tu cuenta.</p>
                <p>En sección "Premios por pagar" podrás comprar los productos que hayas ganado. En "Compra segura" podrás encontrar todos los productos que tienes disponibles para comprar con devolución de tus fichas.</p>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-8">
          <div class="dashboard-widget mb-40">
            <div class="dashboard-title mb-30">
              <h5 class="title">Mi Actividad</h5>
            </div>
            <div class="row justify-content-center mb-30-none">
              <div class="col-md-4 col-sm-6">
                <div class="dashboard-item">
                  <div class="thumb">
                    <img src="../../../assets/images/dashboard/01.png" alt="dashboard">
                  </div>
                  <div class="content">
                    <h2 class="title"><span class="counter">{{getUserData.availableBids}}</span></h2>
                    <h6 class="info">Fichas Disponibles</h6>
                    <router-link to="/bid_bags">Comprar fichas</router-link>
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-sm-6">
                <div class="dashboard-item">
                  <div class="thumb">
                    <img src="../../../assets/images/dashboard/02.png" alt="dashboard">
                  </div>
                  <div class="content">
                    <h2 class="title"><span class="counter">15</span></h2>
                    <h6 class="info">Subastas ganadas</h6>
                    <br>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="dashboard-widget">
            <h5 class="title mb-10">¡Revisa tu participación!</h5>
            <div>
              <ul class="nav-tabs nav">
                <li>
                  <a :class="{ 'active': activeTab === 'pending', 'selected': activeTab === 'pending' }" class="tab-link" @click="setActiveTab('pending')">Premios por pagar</a>
                </li>
                <li>
                  <a :class="{ 'active': activeTab === 'history', 'selected': activeTab === 'history' }" class="tab-link" @click="setActiveTab('history')">Compra segura</a>
                </li>
              </ul>
              <div class="tab-content" :class="{ 'active': activeTab === 'history' }" id="history">
                <table class="purchasing-table">
                  <thead>
                    <tr>
                      <th>Producto</th>
                      <th>Precio</th>
                      <th>Expira</th>
                      <th>Fichas a recibir</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td data-label="Producto">Audifonos Airpods 3a gen</td>
                      <td data-label="Precio">$107.000</td>
                      <td data-label="Expira">Hasta 1 mes después de finalizado el juego</td>
                      <td data-label="Fichas a recibir">63</td>
                      <td><button class="full-width">Comprar</button></td>
                    </tr>
                    <tr>
                      <td data-label="Producto">Audifonos Airpods 3a gen</td>
                      <td data-label="Precio">$107.000</td>
                      <td data-label="Expira">Hasta 1 mes después de finalizado el juego</td>
                      <td data-label="Fichas a recibir">63</td>
                      <td><button class="full-width">Comprar</button></td>
                    </tr>
                    <tr>
                      <td data-label="Producto">Audifonos Airpods 3a gen</td>
                      <td data-label="Precio">$107.000</td>
                      <td data-label="Expira">Hasta 1 mes después de finalizado el juego</td>
                      <td data-label="Fichas a recibir">63</td>
                      <td><button class="full-width">Comprar</button></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="tab-content" :class="{ 'active': activeTab === 'pending' }" id="pending">
                <table class="purchasing-table">
                  <thead>
                    <tr>
                      <th>Nombre producto</th>
                      <th>Precio de compra</th>
                      <th>Fecha de la compra</th>
                      <th>Expira en</th>
                      <th>Comprar</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="game in displayedGames" :key="game.id">
                      <td data-label="Nombre producto">{{ game.item_name }}</td>
                      <td data-label="Precio de compra">{{ formatNumber(game.current_price) }}</td>
                      <td data-label="Fecha de la compra">{{ formatDate(new Date(game.last_bid_timestamp)) }}</td>
                      <td data-label="Expira en">{{ countDownText(game.last_bid_timestamp) }}</td>
                      <td v-if="canPay(game.last_bid_timestamp)" data-label="Comprar">
                        <button @click="payProduct(game.id)" class="full-width">Pagar</button></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="mercado-pago-container"></div>

  </section>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
export default {
  name: 'WonGames',
  data() {
    return {
      games: null,
      currentDate: new Date(),
      activeTab: 'history'
    };
  },
  computed: {
    ...mapGetters ('sessionManager', ['getUserData']),
    displayedGames() {
      if (this.games === null) return [];
      return Object.values(this.games).sort((a, b) => a.startTime > b.startTime ? 1 : -1);
    },
  },
  methods: {
    setActiveTab(tab) {
      this.activeTab = tab;
    },
    payProduct(auctionId) {
      console.log(auctionId);
      axios.post('/payment/create_auction_transaction_order', { auction_id: auctionId })
           .then(response => {
             const mercadoPago = new MercadoPago('APP_USR-2dfdf768-497a-4a26-93eb-bac8d1a67f27', { locale: 'es-CL' });
             mercadoPago.checkout({
               preference: {
                 id: response.data.external_id,
               },
               render: {
                 container: '#mercado-pago-container',
                 label: '¡Los quiero!',
               }
            });
            this.displayCheckoutModal = 'flex';
      })
    },
    countDown(date) {
      return (new Date(date) - this.currentDate) / 1000 + 3600 * 24 * 7;
    },
    canPay(date) {
      return this.countDown(date) >= 0;
    },
    countDownText(date) {
      const secondsLeft = this.countDown(date);
      if (secondsLeft < 0) return 'Pasó el plazo';
      const days = Math.floor(secondsLeft / (3600 * 24));
      const hours = Math.floor((secondsLeft - days * 3600 * 24) / 3600);
      const minutes = Math.floor((secondsLeft - days * 3600 * 24 - hours * 3600) / 60);
      let seconds = Math.floor(secondsLeft - days * 3600 * 24 - hours * 3600 - minutes * 60);
      let daysMessage = days > 0 ? `${days} día` : '';
      daysMessage += days > 1 ? 's' : ' ';
      return `${daysMessage} ${this.padDigits(hours)}:${this.padDigits(minutes)}:${this.padDigits(seconds)}`;
    },
    dateAdvance() {
      setTimeout(() => {
        this.currentDate = new Date();
        this.dateAdvance();
      }, 1000);
    }
  },
  mounted() {
    axios.get('/auctions/won_auctions')
      .then(response => {
        this.games = response.data.reduce((map, obj) => {
          map[obj.id] = obj;
          return map;
        }, {});
      });
    this.dateAdvance();
  }
};
</script>

<style scoped>
.container {
  display: flex;
  flex-wrap: wrap;
}
table, th, td {
  border-collapse: collapse;
}
tr td {
  background-color: #ffffff;
}
td {
  border: 1px solid #6744F1;
  padding: 0px 5px;
  margin: 0px;
}
h6 {
  font-size: 18px;
  margin-top: -7px;
  font-weight: 500;
}
.dashboard-widget {
  padding: 20px;
}
.title {
  font-size: 24px;
  font-weight: bold;
}
.nav-tabs {
  list-style: none;
  padding: 10px;
  display: flex;
}
.nav-tabs li {
  margin-right: 15px;
}
.nav-tabs a {
  text-decoration: none;
  color: #000;
  padding: 10px;
  border-radius: 5px;
  display: inline-block;
}
.nav-tabs a.active {
  background-color: #FDE030;
  color: rgb(255, 255, 255);
  border-radius: 20px;
}
.nav-tabs a.selected {
  background-color: #6744F1;
  color: #ffffff;
  text-decoration: none;
}
.tab-content {
  display: none;
  margin-top: 20px;
  text-align: center;
}
.tab-content.active {
  display: block;
  text-align: center;
}
.purchasing-table {
  width: 100%;
  border-collapse: collapse;
  text-align: center;
}
.purchasing-table th, .purchasing-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}
button {
  border-radius: 20px;
  background-color: #FDE030;
  color: black;
}
button:hover {
  background-color: #ffffff;
  color: #6744F1;
  transition-duration: 0.4s;
}
button:active {
  box-shadow: 4 4px #999;
  transform: translateX(2px) translateY(2px);
  transition-duration: 0.3s;
}
button.full-width {
  width: 100%;
}
@media (max-width: 600px) {
  .nav-tabs {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
  .nav-tabs li {
    margin-right: 5px;
    flex: 1;
  }
  .nav-tabs a {
    text-align: center;
  }
  .purchasing-table {
    display: block;
    width: 100%;
  }
  .purchasing-table thead {
    display: none;
  }
  .purchasing-table tbody tr {
    display: block;
    margin-bottom: 10px;
    border: 1px solid #ddd; /* Para añadir borde alrededor de cada fila en pantallas pequeñas */
  }
  .purchasing-table td {
    display: flex;
    justify-content: space-between;
    text-align: left;
    border: none;
    padding: 5px 0;
    position: relative;
    padding-left: 50%;
    box-sizing: border-box;
  }
  .purchasing-table td:before {
    content: attr(data-label);
    font-weight: bold;
    position: absolute;
    left: 10px;
    width: 45%;
    white-space: nowrap;
    text-align: left;
  }
  .purchasing-table td:last-child {
    padding-left: 10%;
  }
  button.full-width {
    width: calc(100% - 20px);
    margin: 0 auto; /* Centrar el botón */
  }
}
</style>