<template>
  <div class="column">
    <div class="row">
      <label>Precio mercado:</label> <input v-model="marketPrice" type="number" placeholder="Precio mercado">
    </div>
    <div class="row">
      <label>Nombre artículo:</label> <input v-model="itemName" placeholder="Nombre">
    </div>
    <div class="row">
      <img v-if="imageUrl" :src="imageUrl">
    </div>
    <div class="row">
      <label>Foto artículo:</label> <input @change="onFileChange" type="file" placeholder="Imagen">
    </div>
    <div class="row">
      <label>Fecha de inicio:</label> <input v-model="startTime" type="datetime-local" placeholder="Comienzo Juego">
    </div>
    <div class="row">
      <label>Duración de la espera:</label> <input v-model="bidTime" type="number" placeholder="Fin Juego">
    </div>
    <Button
      :onClick="createGame"
      message="Crear Juego"
    />
  </div>
</template>

<script>
import Button from "../utils/Button.vue";

export default {
  name: "CreateGame",
  components: {
    Button,
  },
  data() {
    return {
      wonById: null,
      bidTime: 10,
      startTime: null,
      currentPrice: 0,
      marketPrice: null,
      image: null,
      itemName: null,
    }
  },
  computed: {
    imageUrl() {
      return this.image ? URL.createObjectURL(this.image) : null;
    }
  },
  methods: {
    createGame() {
      if (!this.startTime || !this.itemName) {
        let message = ''
        if (!this.itemName) {
          message += "· Nombre no puede quedar vacío.\n"
        }
        if (!this.startTime) {
          message += "· Fecha de inicio no puede quedar vacía."
        }
        alert(message);
        return;
      }
      let formData = new FormData();
      formData.append('image', this.image);
      formData.append('item_name', this.itemName);
      formData.append('current_price', this.currentPrice);
      formData.append('market_price', this.marketPrice);
      formData.append('bid_duration', this.bidTime);
      formData.append('start_time', this.startTime);
      axios.post('/auctions', formData)
           .then((r) => {
             this.currentPrice = 0;
             this.bidTime = 10;
             this.image = null;
             this.marketPrice = null;
             this.itemName = null;
             this.startTime = null;
           });
    },
    onFileChange (event) {
      this.image = event.target.files[0]
    },
  },
};
</script>

<style scoped>
.centered {
  text-align: center;
}

.price {
  color: rgba(8, 123, 56, 0.619);
  font-size: 15px;
}

h1 {
  text-align: center;
  font-weight: bold;
  margin: 5px 0px;
}

.column {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  margin-bottom: 20px; /* Añade espacio entre las filas */
  color: white;
  padding-left: 10px;
}

.row label {
  width: 150px; /* Ajusta este valor según sea necesario */
  text-align: left;
  margin-right: 10px; /* Espacio entre el label y el input */
}

input {
  width: 200px;
}

img {
  display: block;
  max-width: 100px;
  height: auto;
}

button {
  white-space: normal;
}
</style>