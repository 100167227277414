<template>
  <head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <title>Pami</title>
    <link rel="shortcut icon" type="image/png" href="images/favicon.png">
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Hind&display=swap" rel="stylesheet">
    <link rel="stylesheet" type="text/css" href="app/assets/stylesheets/flaticon.css"> 
  </head>
  <body class="application">
    <div class="container text-center mt-5" >
      <NavBar/>
      <div class="col-md-12 padded">
        <router-view></router-view>
      </div>
    <div class="container text-center mt-5">
      <Footer/>
    </div>  
    </div>
  </body>
</template>

<script>
import NavBar from './NavBar.vue'
import Footer from './Footer.vue';
export default {

  // Defino el componente 'Postres' 
  components: {
    NavBar,
    Footer,
},
  data() {
    return {
      titulo: "Lista de Postres"
    }
  },
  computed: {
    testing() {
      return 25;
    },
  }
}
</script>

<style scoped>
.application {
  font-family: 'Hind', sans-serif;
  background-image: url("../assets/images/banner/hero-bg.png");
  background-repeat: no-repeat;
}

.router-link {
  padding: 0 10px;
}

.padded {
  padding: 80px 0;
}
</style>
