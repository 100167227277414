<template>
  <section class="account-section padding-bottom">
    <div class="container">
      <div class="account-wrapper">
        <div class="left-side">
          <div class="section-header">
            <h2 class="title">REGÍSTRATE</h2>
            <p>Te regalaremos 20 fichas para participar por los productos que tú quieras</p>
          </div>
          <div class="login-form">
            <div class="form-group mb-30">
              <label for="user-input"><i class="far fa-envelope"></i></label>
              <input v-model="fullName" type="text" id="user-input" placeholder="Nombre de usuario">
            </div>
            <div class="form-group mb-30">
              <label for="login-email"><i class="far fa-envelope"></i></label>
              <input v-model="email" type="text" id="login-email" placeholder="Correo electrónico">
            </div>
            <span v-if="email && !isValidEmail" class="error-message">Correo electrónico no válido</span>
            <div class="form-group mb-30">
              <div>La contraseña debe tener un mínimo de 6 caracteres con al menos 1 caracter especial</div>
              <label for="login-pass"><i class="fas fa-lock"></i></label>
              <input v-model="password" :type="passwordFieldType" id="login-pass" placeholder="Contraseña">
              <span class="pass-type" @click="togglePasswordVisibility"><i :class="passwordIcon"></i></span>
            </div>
            <span v-if="password && !isValidPassword" class="error-message">Contraseña insegura</span>
            <div class="form-group mb-30">
              <label for="login-pass-confirmation"><i class="fas fa-lock"></i></label>
              <input v-model="passwordConfirmation" :type="passwordConfirmationFieldType" id="login-pass-confirmation" placeholder="Repite tu contraseña">
            </div>
            <span v-if="password && passwordConfirmation && !passwordsMatch" class="error-message">Las contraseñas no coinciden</span>
            <div class="form-group checkgroup mb-30">
              <input v-model="termsAccepted" type="checkbox" name="terms" id="check">
              <label for="check">  Acepto <a href="https://drive.google.com/file/d/1-59tqA-s1cVxQDMNHMiKtffrp9GkX2xm/view?pli=1" target="_blank">Términos y Condiciones</a></label>
            </div>
            <div class="form-group mb-0">
              <button @click="register" :disabled="!isFormComplete" :class="{'custom-button-disabled': !isFormComplete}" class="custom-button">¡COMENZAR!</button>
            </div>
          </div>
        </div>
        <div class="right-side cl-white">
          <div class="section-header mb-0">
            <h3 class="title mt-0">¿YA TIENES UNA CUENTA?</h3>
            <p>Ingresa y comienza a jugar</p>
            <router-link class="router-link custom-button transparent" to="/login">Ingresar</router-link>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- Modal -->
  <div v-if="showModal" class="modal">
    <div class="modal-content">
      <img src="URL_DE_TU_IMAGEN" alt="Imagen de confirmación" class="modal-image">
      <p>Te hemos enviado un correo para verificar tu cuenta.</p><p>Revisa tu correo electrónico (incluida la carpeta de Spam o correo no deseado) para finalizar tu registro.</p>
      <button class="botonmodal" @click="showModal = false">¡OK!</button>
    </div>
  </div>
</template>

<script>
import Button from "../utils/Button.vue";
import { mapActions, mapGetters } from 'vuex'
export default {
  name: "Register",
  components: {
    Button,
  },
  data() {
    return {
      email: null,
      emailConfirmation: null,
      password: null,
      passwordConfirmation: null,
      fullName: null,
      termsAccepted: false,
      showPassword: false,
      showPasswordConfirmation: false,
      showModal: false,  // Nuevo dato reactivo para mostrar el modal
    }
  },
  computed: {
    ...mapGetters('sessionManager', ['getAuthToken', 'getUserId', 'isLoggedIn']),
    isValidEmail() {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailPattern.test(this.email);
    },
    isValidPassword() {
      const passwordPattern = /^(?=.*[0-9!@#$%^&*])(?=.{6,})/;
      return passwordPattern.test(this.password);
    },
    passwordsMatch() {
      return this.password === this.passwordConfirmation;
    },
    isFormComplete() {
      return this.email && this.isValidEmail && this.password && this.isValidPassword && this.passwordConfirmation && this.passwordsMatch && this.fullName && this.termsAccepted; 
    },
    passwordFieldType() {
      return this.showPassword ? 'text' : 'password';
    },
    passwordIcon() {
      return this.showPassword ? 'fas fa-eye-slash' : 'fas fa-eye';
    },
    passwordConfirmationFieldType() {
      return this.showPasswordConfirmation ? 'text' : 'password';
    },
    passwordConfirmationIcon() {
      return this.showPasswordConfirmation ? 'fas fa-eye-slash' : 'fas fa-eye';
    }
  },
  methods: {
    ...mapActions('sessionManager', ['registerUser']),
    register() {
      if (!this.isFormComplete) return;
      const user = {
          email: this.email,
          password: this.password,
          password_confirmation: this.passwordConfirmation,
          full_name: this.fullName,
      }
      this.registerUser({ user }).then(() => {
        this.showModal = true; // Mostrar el modal después del registro exitoso
      });
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    togglePasswordConfirmationVisibility() {
      this.showPasswordConfirmation = !this.showPasswordConfirmation;
    }
  },
};
</script>

<style scoped>
h1 {
  text-align: center;
  font-weight: bold;
  margin: 5px 0px;
}
img {
  max-height: 150px;
  max-width: 100%;
}
button {
  border: none;
  outline: 0;
  display: inline-block;
  padding: 8px;
  color: white;
  background-color: Grey;
  text-align: center;
  cursor: pointer;
  width: 66%;
  transition-duration: 0.1s;
  border-radius: 9px;  
  box-shadow: 1.5px 3px #999;
  font-weight: bold;
  font-family: 'Hind', sans-serif;
}
.custom-button {
  /* Estilos del botón habilitado */
}
.custom-button:hover {
  background-color: #FDE030;
  color: white;
}
.custom-button:active {
  background-color: #FFDB00;
  box-shadow: 0 0px #999;
  transform: translateX(1.5px) translateY(1.5px);
}
.custom-button:focus {
  background-color: gold;
}
.custom-button-disabled {
  background-color: lightgrey;
  color: grey;
  cursor: not-allowed;
}
.custom-button-disabled:hover {
  background-color: lightgrey;
  color: grey;
}
.error-message {
  color: red;
  font-size: 0.9em;
  margin-top: 5px;
}
.pass-type {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.form-group {
  position: relative;
}
.form-group label {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-5%);
}
input[type="text"],
input[type="password"] {
  padding-left: 40px; /* Ajusta según sea necesario para que el ícono no se superponga al texto */
  height: 40px; /* Ajusta la altura de los campos de entrada según sea necesario */
  box-sizing: border-box;
}
.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
}
.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 500px;
  text-align: center;
}
.modal-image {
  max-width: 100%;
  height: auto;


  margin-bottom: 20px;
}
.botonmodal {
  display: block;  /* Cambia el display a block */
  margin: 20px auto;  /* Utiliza margin auto para centrar */
  border: none;
  outline: 0;
  padding: 10px 20px;
  color: white;
  background-color: Grey;
  text-align: center;
  cursor: pointer;
  transition-duration: 0.1s;
  border-radius: 15px;  
  font-weight: bold;
  font-family: 'Hind', sans-serif;
}
.botonmodal:hover {
  background-color: #FDE030;
  color: white;
}
.botonmodal:active {
  background-color: #FFDB00;
}
.botonmodal:focus {
  background-color: gold;
}
</style>