<template>
  <div class="container text-center mt-5">
    Gracias por tu compra! Hemos añadido los bids a tu cuenta.
  </div>
</template>

<script>
export default {
  name: 'PaymentConfirmation',
  data() {
    return {}
  },
  computed: { },
  methods: { },
}
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.bid-plan {
  margin: 5px 0px;
}
</style>
