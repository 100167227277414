/* eslint no-console: 0 */
// Run this example by adding <%= javascript_pack_tag 'hello_vue' %> (and
// <%= stylesheet_pack_tag 'hello_vue' %> if you have styles in your component)
// to the head of your layout file,
// like app/views/layouts/application.html.erb.
// All it does is render <div>Hello Vue</div> at the bottom of the page.

import { createApp } from 'vue'
import { createWebHashHistory, createRouter } from "vue-router";
import { store } from './stores/index'

import App from '../app.vue';
import GamesIndex from './games/Index.vue';
import WonGames from './games/WonGames.vue';
import CreateGame from './games/CreateGame.vue';
import AboutUs from './static_pages/AboutUs.vue';
import LogIn from './session/LogIn.vue';
import Register from './session/Register.vue';
import BidBagIndex from './bid_bags/Index.vue';
import Faq from './static_pages/FAQ.vue';
import PaymentConfirmation from './webpay/PaymentConfirmation.vue';
import formattingMixin from './utils/formattingMixin'
// Creamos una variable global 'axios' 
window.axios = require('axios');
const routes = [
  { path: '/games', name: 'games', component: GamesIndex },
  { path: '/', name: 'home', component: GamesIndex },
  { path: '/about', name: 'about', component: AboutUs },
  { path: '/games/new', name: 'games/new', component: CreateGame },
  { path: '/games/won_games', name: 'games/won_games', component: WonGames },
  { path: '/login', name: 'login', component: LogIn },
  { path: '/bid_bags', name: 'bid_bags', component: BidBagIndex },
  { path: '/sign_up', name: 'sign_up', component: Register },
  { path: '/faq', name: 'faq', component: Faq },
  { path: '/payment_confirmation', name: 'payment_confirmation', component: PaymentConfirmation },
];

const router = createRouter({
  // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
  history: createWebHashHistory(),
  routes, // short for `routes: routes`
});
let localAuthToken = localStorage.auth_token;
let cookieExists = localAuthToken !== 'undefined' && localAuthToken !== null;
if (cookieExists) {
  const authToken = localStorage.getItem('auth_token');
  const authTokenExists = authToken !== 'undefined' && authToken !== null;
  if (authTokenExists) {
    store.dispatch('sessionManager/loginUserWithToken', { auth_token: authToken })
         .then(() => {});
  }
}
const app = new createApp(App);
app.use(router);
app.use(store);
app.mixin(formattingMixin);
app.mount('#app')

// The above code uses Vue without the compiler, which means you cannot
// use Vue to target elements in your existing html templates. You would
// need to always use single file components.
// To be able to target elements in your existing html/erb templates,
// comment out the above code and uncomment the below
// Add <%= javascript_pack_tag 'hello_vue' %> to your layout
// Then add this markup to your html template:
//
// <div id='hello'>
//   {{message}}
//   <app></app>
// </div>


// import Vue from 'vue/dist/vue.esm'
// import App from '../app.vue'
//
// document.addEventListener('DOMContentLoaded', () => {
//   const app = new Vue({
//     el: '#hello',
//     data: {
//       message: "Can you say hello?"
//     },
//     components: { App }
//   })
// })
//
//
//
// If the project is using turbolinks, install 'vue-turbolinks':
//
// yarn add vue-turbolinks
//
// Then uncomment the code block below:
//
// import TurbolinksAdapter from 'vue-turbolinks'
// import Vue from 'vue/dist/vue.esm'
// import App from '../app.vue'
//
// Vue.use(TurbolinksAdapter)
//
// document.addEventListener('turbolinks:load', () => {
//   const app = new Vue({
//     el: '#hello',
//     data: () => {
//       return {
//         message: "Can you say hello?"
//       }
//     },
//     components: { App }
//   })
// })
