<template>
  <header>
    <div :class="{'header-bottom': true, 'active': activeClass}" @scroll="handleScroll">
      <div class="container">
        <div class="header-wrapper">
          <div class="logo">
            <img src="../assets/images/logo.png" alt="logo">
          </div>
          <ul :class="{'menu': true, 'ml-auto': true, 'active': activeMenu}">
            <li>
              <router-link class="router-link" @click="closeDropdown" to="/games">Juegos en curso</router-link>
            </li>
            <li>
              <router-link v-if="isAdmin" class="router-link" @click="closeDropdown" to="/bid_bags">Comprar fichas </router-link>
            </li>
            <li>
              <router-link class="router-link" @click="closeDropdown" to="/faq">Preguntas Frecuentes</router-link>
            </li>
            <li v-if="isLoggedIn">
              <a to="/games/won_games">Tienes {{this.getUserData.availableBids}} Fichas</a>
            </li>
            <li :class="{'menu-item-has-children': true, open: submenuDisplay.account}">
              <a>Cuenta</a>
              <ul class="submenu" :style="{'display': submenuDisplay.account ? 'block' : 'none'}">
                <template v-if="isLoggedIn">
                  <li >
                    <router-link class="router-link" @click="closeDropdown" to="/games/won_games"> Mi Perfil</router-link>
                  </li>
                  <li>
                    <a class="router-link split" @click="logoutUser" >Salir</a>
                  </li>
                </template>
                <template v-else>
                  <li>
                    <router-link class="router-link" @click="closeDropdown" to="/login">Ingresar</router-link>
                  </li>
                  <li>
                    <router-link class="router-link" @click="closeDropdown" to="/sign_up">Regístrate</router-link>
                  </li>
                </template>
              </ul>
            </li>
            <li v-if="isAdmin" class="menu-item-has-children">
              <a>Administracion</a>
              <ul class="submenu">
                <li>
                  <router-link class="router-link" to="/games/new">Crear Juego</router-link>
                </li>
              </ul>
            </li>
          </ul>
          <div :class="{'header-bar': true, 'd-lg-none': true, 'active': activeMenu}" @click="activeMenu = !activeMenu">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
    </div>

    <div class="whatsapp-container">
        <div class="whatsapp-text">¿Dudas? ¡Pregúntanos!</div>
        <a href="https://wa.me/569" target="_blank">
            <img src="../assets/images/logowhatsapp.png" alt="WhatsApp" class="whatsapp-logo">
        </a>
    </div>
  </header>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Button from './packs/utils/Button.vue';
export default {
  name: "NavBar",
  components: {Button},
  data() {
    return {
      titulo: "Lista de Postres",
      displayModal: '',
      email: '',
      password: '',
      windowTop: 0,
      activeMenu: false,
      submenuDisplay: {
        account: true,
      }
    }
  },
  computed: {
    ...mapGetters('sessionManager', ['getUserData', 'isLoggedIn', 'isAdmin']),
    activeClass() {
      return this.windowTop >= 10;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    ...mapActions('sessionManager', ['logoutUser', 'loginUser']),
    onScroll(e) {
      this.windowTop = window.top.scrollY;
    },
    openLogIn() {
      this.displayModal = 'block';
    },
    logoutUser() {
    // Realiza las acciones necesarias al cerrar sesión
    this.$store.dispatch('sessionManager/logoutUser')
      .then(() => {
        this.activeMenu = false; // Cierra el menú desplegable al hacer clic en "Salir"
      })
      .catch(error => {
        console.error('Error al cerrar sesión:', error);
      });
  },
  closeDropdown() {
    this.activeMenu = false;
  },
    logIn() {
      this.loginUser({user: {email: this.email, password: this.password }})
        .then(() => {
          this.displayModal = 'none';
        });
    },
    closeLogIn() {
      this.displayModal = 'none';
    },
  }
}

</script>

<style scoped>

.topnav {
  overflow: hidden;
  background-color: #6744F1;
  border-radius: 15px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
}
input {
  width: 90%;
}

.topnav a {
  float: left;
  color: white;
  text-align: center;
  padding: 8px 16px;
  text-decoration: none;
  font-size: 12px;
  box-sizing: border-box;
}

.topnav a:hover {
  background-color: #FFDB00;
  color: black;
  font-weight: bold;
  box-sizing: border-box;
}

/* Create a right-aligned (split) link inside the navigation bar */
.topnav a.split {
  float: right;
  background-color: gray;
  color: white;
  box-sizing: border-box;
}
.topnav a.splita {
  float: right;
  background-color: #6744F1;
  color: white;
  box-sizing: border-box;
}

.modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
  padding-top: 60px;
  border-radius: 50px;
  box-sizing: border-box;
}

.botoningresar {
  border: none;
  outline: 0;
  display: inline-block;
  padding: 8px;
  color: white;
  background-color: Grey;
  text-align: center;
  cursor: pointer;
  width: 66%;
  transition-duration: 0.1s;
  border-radius: 9px;	
  box-shadow: 1.5px 3px #999;
  font-weight: bold;
  box-sizing: border-box;
}
.botoningresar :hover {
  background-color: #FDE030;
  color: white;
  box-sizing: border-box;
}
.botoningresar:active {
  background-color: #FFDB00;
  box-shadow: 0 0px #999;
  transform: translateX(1.5px) translateY(1.5px);
  box-sizing: border-box;
}
.close {
  position: absolute;
  right: 25px;
  top: 0;
  color: #000;
  font-size: 35px;
  font-weight: bold;
  box-sizing: border-box;
}


.close:hover,
.close:focus {
  color: red;
  cursor: pointer;
  box-sizing: border-box;
}

.animate {
  -webkit-animation: animatezoom 0.6s;
  animation: animatezoom 0.6s;
  box-sizing: border-box;
}

@-webkit-keyframes animatezoom {
  from {-webkit-transform: scale(0)}
  to {-webkit-transform: scale(1)}
}

@keyframes animatezoom {
  from {transform: scale(0)}
  to {transform: scale(1)}
}
.avatar {
  vertical-align: center;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  top-padding: 10px;
  box-sizing: border-box;
}

.ingresartexto {
  border-radius: 10px; 
  border: red;
  box-sizing: border-box;
}

.cancelbtn {
  border-radius: 15px;
  color: white;
  background-color: blue;
  box-sizing: border-box;
}

.cancelbtn:hover {
  background-color: red; 
  color: white;
  box-sizing: border-box;
}

.whatsapp-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    text-align: center;
    z-index: 1000; /* Asegura que esté por encima de otros elementos */
}

.whatsapp-text {
    margin-bottom: 5px;
    font-size: 15px;
    color: #000;
    background-color: rgba(255, 255, 255, 0.8); /* Fondo semitransparente para mejor visibilidad */
    padding: 2px 5px;
    border-radius: 3px;
}

.whatsapp-logo {
    width: 50px;
    height: 50px;
    cursor: pointer;
    transition: transform 0.3s; /* Animación suave al hacer hover */
}

.whatsapp-logo:hover {
    transform: scale(1.1); /* Aumenta el tamaño del logo al hacer hover */
}

@media (max-width: 600px) {
    .whatsapp-text {
        font-size: 12px; /* Reduce el tamaño del texto en pantallas pequeñas */
    }

    .whatsapp-logo {
        width: 40px;
        height: 40px; /* Reduce el tamaño del logo en pantallas pequeñas */
    }

    .whatsapp-container {
        bottom: 10px;
        right: 10px; /* Ajusta la posición en pantallas pequeñas */
    }
  }
</style>
