<template>
    <!--:style="computedStyle" -->
  <div class="auction-item-2">
    <a class="product-name">
          <a>{{itemName}}</a>
    </a>
    <div class="auction-thumb">
      <a>
        <img :src="image">
      </a>
    </div>
    <div class="auction-content">
      <h6>
          <a class="current-winner">{{wonByName}}</a>
      </h6>
      <div class="bid-area">
          <div class="bid-amount">
              <div class="icon">
                  <i class="flaticon-auction"></i>
              </div>
              <div class="amount-content">
                  <div class="current">Precio Actual</div>
                  <div class="amount">${{ formatNumber(currentPrice) }}</div>
              </div>
          </div>
          <div class="bid-amount">
              <div class="icon">
                  <i class="flaticon-money"></i>
              </div>
              <div class="amount-content">
                  <div class="current">
                      <a title="Podrás comprar el producto al precio mencionado, y recibirás todas las fichas utilizadas durante este juego" href="./dashboard.html">Comprar segura</a></div>
                  <div class="amount">
                      <a>${{ formatNumber(marketPrice) }}</a>
                  </div>
              </div>
          </div>
          <div>{{}}</div>
      </div>
      <div class="countdown">
          {{ countDownText }}
      </div>
      <div class="text-center">
        <Button class="game-button"
          v-if="!hideBidButton"
          :onClick="bidOnAuction"
          :message="auctionMessage"
        />
        <p v-else>
          {{bidButtonReplacementText}}
        </p>
     </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex';
import Button from "../utils/Button.vue";
export default {
  name: "GameSummary",
  components: {
    Button,
  },
  props: {
    id: { type: Number, required: true },
    itemName: { type: String, required: true },
    currentPrice: { type: Number, required: true },
    remainingTime: { type: Number, required: true },
    timeUntillStart: { type: Number, required: true },
    wonById: { type: Number, required: false },
    wonByName: { type: String, required: false },
    marketPrice: { type: Number, required: false },
    image: {type: String, required: false}
  },
  data() {
    return {
      currentDate: new Date(),
    }
  },
  computed: {
    ...mapGetters('sessionManager', ['isLoggedIn', 'getUserId']),
    auctionMessage() {
      if (this.isLoggedIn && this.started) return '¡Pami!';
      if (this.isLoggedIn && !this.started) return 'Espera a que empiece para poder participar';
      if (!this.isLoggedIn && !this.started) return '¡Registrate antes de que empieze!';
      if (!this.isLoggedIn && this.started) return '¡Inicia sesión para participar!'
    },
    customBoxClass(){
      if (this.isLoggedIn && this.started) return '¡Pami!';
      if (this.isLoggedIn && !this.started) return 'itemagendado';
      if (!this.isLoggedIn && !this.started) return '¡Regístrate antes de que empiece!';
      if (!this.isLoggedIn && this.started) return '¡Inicia sesión para participar!'
    },
    started() {
      return this.timeUntillStart < 0;
    },
    hideBidButton() {
      return this.countDown <= 0 || this.getUserId == this.wonById && this.started || !this.started;
    },
    bidButtonReplacementText() {
      if (!this.started) return '';
      if (this.getUserId == this.wonById && this.countDown > 0) return 'Vas ganando!';
      if (this.getUserId != this.wonById && this.countDown > 0 && this.wonByName) return `Va ganando ${this.wonByName}!`;
      return ''
    },
    countDownText(){
      if (!this.started) {
        const days = Math.floor(this.countDown / (3600*24));
        const hours = Math.floor((this.countDown - days * 3600*24)/3600);
        const minutes = Math.floor((this.countDown - days * 3600*24 - hours * 3600)/60);
        let seconds = Math.floor(this.countDown - days * 3600*24 - hours * 3600 - minutes * 60);
        let daysMessage = days > 0 ? `${days} día` : '';
        daysMessage += days > 1 ? 's ' : ' ';
        seconds = seconds < 10 ? '0' + seconds : seconds;
        return `El juego comienza en ${daysMessage}${hours}:${minutes > 10 ? minutes : '0' + minutes}:${seconds}`
      } else if (this.countDown <= 0) {
        let message = 'Juego terminado\n\n';
        if (this.wonById === null) {
          message += 'No hubo ganadores'
        } else {
          message += this.wonById === this.getUserId ? '¡Felicidades, ganaste este juego!' : `¡Ha ganado ${this.wonByName}!`;
        }
        return message;
      }
      return this.countDown;
    },
    /*computedStyle() {
      if (!this.started ) {
        return { 'background-color': '#00994d' };
      } else if (this.countDown < 0) {
        return { 'background-color': '#8c8c8c' }
      }
      return {};
    },*/
    countDown() {
      if (!this.started){
        return Math.round(this.timeUntillStart);
      }
      return Math.round(this.remainingTime);
    },
  },
  methods: {
    ...mapMutations('sessionManager', ['setAvailableBids']),
    openShowAuctionModal() {},
    bidOnAuction() {
      if (!this.isLoggedIn && this.started) {
        this.$router.push('/login')
      } else if (!this.isLoggedIn && !this.started) {
        this.$router.push('/sign_up')
      } else {
        axios.post(`/auctions/${this.id}/bid`, { seen_price: this.currentPrice })
             .then(response => {
               this.$store.commit('sessionManager/setAvailableBids', response.data.available_bids);
               if (response.data.message != 'OK') alert(response.data.message);
             });
      }
    },
  }
};
</script>

<style scoped>
.game-button:hover {
  background-color: #FDE030;
  color: white;
}
.game-button:active {
  background-color: #FFDB00;
  box-shadow: 0 0px #999;
  transform: translateX(1.5px) translateY(1.5px);
}
.game-button:focus{
   background-color:gold;
}
.product-name {
  color: black;
  font-size: 30px;
  text-shadow: #999;
}
.current-winner{
  font-size: 20px;
  color:#999;
  margin-top: 10px;
  margin-bottom: 6px;
}

@media (max-width: 600px) {
  .product-name{
    font-size: 20px;

  }
  .current-winner{
    font-size: 15px;
  }
}
</style>
