<template>
  <div>
    <h1>
      Hola!
    </h1>
    <p>
      Esta es la primera versión de la aplicación pami.
    </p>    
  </div>
</template>

<script>

export default {
  name: 'AboutUs',
}
</script>

<style scoped>
.container {
  display: flex;
  flex-wrap: wrap;
}
</style>
