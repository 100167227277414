<template>
  <div class="faq">
    <h1>Preguntas Frecuentes</h1>

    <div v-for="faq in FAQs" :key="faq.id" class="question">
      <div class="question-header" @click="toggleAnswerShow(faq.id)">
        <img src="../../../assets/images/logo/footer-logo.png" alt="Logo" class="logo">
        <h2 class="question-title">
          {{ faq.question }}
        </h2>
      </div>
      <div class="answer-box">
      <div class="answer" v-if="displayAnswer(faq.id)">
        <p>{{ faq.answer }}</p>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Faq',
  data() {
    return {
      shownAnswerIndex: null,
      FAQs: [
        { 
          id: 1,
          question: '¿Qué es Pami?',
          answer: 'Pami es una plataforma de entretenimiento en línea donde comprar productos no solo es fácil, ¡sino también divertido! Con Pami, puedes conseguir productos increíbles con hasta un 95% de descuento. Únete a la diversión y descubre grandes ofertas.',
        },
        {
          id: 2,
          question: '¿Cómo puedo registrarme en el sitio?',
          answer: '¡Es muy fácil y rápido! Haz clic en el botón "Registrarse" en la esquina superior derecha de la página principal, ingresa tu correo, nombre de usuario y contraseña y ¡listo! Como bienvenida, te regalamos 20 fichas para que empieces a jugar de inmediato. Deberás verificar tu correo electrónico una vez registrado, por lo que no olvides revisar la carpeta de Correo no deseado en tu mail',
        },
        { 
          id: 3,
          question: '¿Cómo funciona?',
          answer: '¡Comprar en Pami es emocionantes y rápido! Cada producto tiene un temporizador de 10 segundos. Cada vez que alguien hace clic en "Pami", el precio del producto aumenta en $5 y el temporizador se reinicia a 10 segundos. Si eres el último en presionar el botón cuando el temporizador llega a 00:00, ¡felicidades! Te llevas el producto al precio alcanzado. ¡Es una carrera contra el reloj y contra otros jugadores!',
        },
        { 
          id: 4,
          question: '¿Cómo puedo participar por un producto?',
          answer: 'Una vez registrado e iniciado sesión, busca el producto que te interese y haz clic en "Pami". Aparecerá en el detalle del producto el mensaje "Vas ganando". ¡Así de simple! Con tus fichas de bienvenida, ya puedes empezar a ganar increíbles productos.',
        },
        { 
          id: 5,
          question: '¿Es segura la plataforma Pami?',
          answer: '¡Totalmente! Pami es 100% segura. Contamos con encriptación de punta a punta y certificación SSL para proteger tus datos. Además, nuestras transacciones y métodos de pago están certificados por MercadoPago, garantizando la seguridad de tus compras.',
        },
        { 
          id: 6,
          question: '¿Qué formas de pago aceptan?',
          answer: 'Por ahora aceptamos MercadoPago como método de pago oficial. Esto te permitirá realizar compra de fichas con tarjeta de crédito, debito o abono que tengas en tu cuenta de MercadoPago. ¡Fácil y seguro!',
        },
        { 
          id: 7,
          question: '¿Cómo puedo saber si gané un producto?',
          answer: 'Dentro del resumen del producto en el que participaste aparecerá el mensaje "¡Felicitaciones! Haz obtenido el producto! Además, recibirás una notificación por correo electrónico si eres el afortunado ganador. También puedes verificar el estado de los productos en los que concursaste en "Mi Perfil", en la sección "Premios por pagar". ¡Mantente atento!',
        },
        { 
          id: 8,
          question: '¿Qué pasa si no gano un producto?',
          answer: '¡Que no cunda el pánico! Si no ganas un producto después de utilizar tus fichas, no te preocupes. Tienes la opción de la "Compra Segura", la cual permite comprar el producto a precio normal indicado en los detalles de cada juego y te devolvemos todas las fichas que hayas utilizado en ese producto. Los jugadores que hayan participado por un producto tienen hasta 30 días  para realizar la compra a través de "Compra Segura". ¡Así no pierdes nada!',
        },
        { 
          id: 9,
          question: '¿Cuánto tiempo tengo para canjear el producto que gané?',
          answer: 'Si eres el ganador de uno de los productos en los que participaste tendrás hasta 60 días para poder realizar la compra. Transcurrido este tiempo, el producto desaparecerá de la sección "Premios por pagar", renunciando al derecho a compra.',
        },
        { 
          id: 10,
          question: '¿Cómo se envían los artículos ganados?',
          answer: 'Los artículos ganados serán enviados a la dirección que nos proporciones. Te notificaremos a través de nuestros medios de contacto el estado de tu producto y el código de seguimiento. ¡Y lo mejor de todo, el envío es gratis con despacho a todo Chile! ¡Espera tu premio en la puerta de tu casa!',
        },
        { 
          id: 11,
          question: '¿Son nuevos los productos ofrecidos en Pami?',
          answer: 'Sí, todos los productos que ofrecemos son nuevos y completamente sellados. Además, cuentan con la garantía legal del fabricante, asegurando que recibas productos de la mejor calidad.',
        },
        { 
          id: 12,
          question: '¿Puedo devolver un artículo si no estoy satisfecho?',
          answer: 'Nuestra política de devoluciones depende del tipo de artículo y del fabricante. Fichas promocionales no poseen devolución. ¡Queremos que estés feliz con tus compras!',
        },
        { 
          id: 13,
          question: '¿Cómo puedo contactar con el servicio al cliente?',
          answer: 'Puedes contactar a nuestro equipo de servicio al cliente a través del formulario de contacto en nuestro sitio web, enviando un correo a agustin@pami.cl, o enviando un Whatsapp directo a través de la página web. Intentaremos responderte lo más rápido posible. ¡Estamos aquí para ayudarte!',
        },
        { 
          id: 14,
          question: '¿Qué debo hacer si tengo un problema técnico en el sitio?',
          answer: 'Si encuentras un problema técnico, intenta recargar la página o utilizar otro navegador: el 95% de los problemas se resuelven de esta manera. Errar es humano: si el problema persiste, contacta a nuestro equipo de soporte técnico para asistencia. ¡No te preocupes, queremos que lo pases bien. Lo solucionaremos rápido!',
        },
        { 
          id: 15,
          question: '¿Cómo puedo vender mis artículos en el sitio?',
          answer: 'Si eres un fabricante o tienes productos que cuentan con garantía legal en Chile, puedes comunicarte con nosotros a través de nuestros canales de contacto. ¡Queremos que tus productos estén en Pami!',
        },
      ],
    }
  },
  methods: {
    displayAnswer(answerIndex) {
      return this.shownAnswerIndex === answerIndex;
    },
    toggleAnswerShow(answerIndex) {
      this.shownAnswerIndex = this.displayAnswer(answerIndex) ? null : answerIndex;
    },
  }
}
</script>

<style scoped>
h1 {
  border-color: rgba(0, 0, 0, 0);
  color: white;
  background-color: rgba(211, 211, 211, 0);
  margin-bottom: 40px;
}
.question-title {
  cursor: pointer;
  background-color: #6744f1;
  padding: 10px;
  border-radius: 20px;
  margin-bottom: 15px;
  text-align: left;
  color: #ffffff;
  font-size: 20px;
}
.answer {
  padding: 20px;
  background-color: #3e00b2;
  border-radius: 20px;
  text-align: left;
  margin-bottom: 20px;
  color: white;
  word-wrap: break-word;
  white-space: pre-wrap;
}
.answer-box {
  padding: 10px;
  margin-bottom: 20px;
  padding-left: 40px;
  padding-right: 40px;
  overflow: hidden;
  word-wrap: break-word;
  white-space: pre-wrap;
}
answer-box {
  padding: 10px;
  margin-bottom: 20px;
  padding-left: 40px;
  padding-right: 40px;
}
.logo {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}
.question-header {
  display: flex;
  align-items: center;
}
</style>