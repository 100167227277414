<template>
  <div class="container text-center mt-5">
    <BidBag
      v-for="bidBagPlan in bidBagPlans"
      :key="bidBagPlan.id"
      :selected="bidBagPlan.id === selectedBidBagPlan.id"
      :bidBagPlan="bidBagPlan"
      :onSelect="buyBids"/>
  </div>
      <div 
        :style="modalStyle"
        class="index-modal column"
        >
      <!-- Modal Content -->
        <div v-if="!!selectedBidBagPlan.id" class="modal-content animate">
          Estás comprando {{ selectedBidBagPlan.bids }} fichas por $ {{ formatNumber(selectedBidBagPlan.price) }} pesos
          <div id="mercado-pago-container"></div>
          <button @click="cancelPurchase" type="button" class="transparent">Cancelar</button>
        </div>
      </div>
</template>

<script>
import Button from "../utils/Button.vue";
import BidBag from "./BidBag.vue"
export default {
  name: 'BidBagIndex',
  components: {
    Button,
    BidBag,
  },
  mounted() {
    axios.get('/bid_bag_plans')
         .then(response => {
           this.bidBagPlans = response.data.sort((a, b) => a.bids > b.bids);
         });
  },
  data() {
    return {
      bidBagPlans: [],
      webpayUrl: null,
      webpayToken: null,
      selectedBidBagPlan: {},
      displayCheckoutModal: 'none',
    }
  },
  computed: {
    modalStyle() {
      return { display: this.displayCheckoutModal };
    },

    sortedBigBagPlans() {
      return this.bidBagPlans.sort()
    },
  },
  methods: {
    cancelPurchase() {
      this.displayCheckoutModal = 'none';
      this.selectedBidBagPlan = {};
    },
    buyBids(bidBagPlan) {
      axios.post('/payment/create_transaction_order', {bid_bag_plan_id: bidBagPlan.id})
           .then(response => {
             const mercadoPago = new MercadoPago('APP_USR-2dfdf768-497a-4a26-93eb-bac8d1a67f27', { locale: 'es-CL' });
             mercadoPago.checkout({
               preference: {
                 id: response.data.external_id,
               },
               render: {
                 container: '#mercado-pago-container',
                 label: '¡Los quiero!',
               }
            });
            this.displayCheckoutModal = 'flex';
            this.selectedBidBagPlan = bidBagPlan
      });
    },
  },
}
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.precio {
  color: white;
  text-shadow: 0.1px 0px gray;
  font-size: 32px; 
  font-weight: bold;
}

.extra-info {
  font-size: 10px;
  display: inline-block;
  color: white;
  text-align: center;
  cursor: pointer;
  width: 100%;
  transition-duration: 0.1s;
  border-radius: 12px;
  text-decoration: none;
}

.modal-content {
  background: -moz-linear-gradient(90deg, #3da9f5 0%, #683df5 100%);
  background: -ms-linear-gradient(90deg, #3da9f5 0%, #683df5 100%);
  background: -webkit-linear-gradient(90deg, #3da9f5 0%, #683df5 100%);
  padding: 10px 10px;
  color: rgb(255, 255, 255);
}

.index-modal {
  position: fixed;
  z-index: 999999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 30% 30%;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
  padding-top: 60px;
  border-radius: 50px;
  box-sizing: border-box;
}

</style>
