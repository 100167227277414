<template>
  <div class="box">
    <div class="bid-amount"> {{ bidBagPlan.bids }}<br>FICHAS</div>
    <div class="precio">$ {{ formatNumber(bidBagPlan.price) }} pesos </div>
    <button @click="onSelect(bidBagPlan)" class="custom-button">¡Comprar!</button>
  </div>
</template>

<script>
export default {
  name: 'BidBag',
  props: {
    bidBagPlan: { type: Object, required: true },
    selected: {type: Boolean, required: true },
    onSelect: {type: Function, required: true },
  },
  computed: {
    pricePerBid() {
      return (this.bidBagPlan.price / this.bidBagPlan.bids).toFixed(2);
    },
  },
}
</script>

<style scoped>
.box {
  margin: 5px;
  padding: 10px 15px;
  background-color: white;
  border-radius: 10%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex: 1 0 45%; /* Ensures two columns on small screens */
}

@media (min-width: 769px) {
  .box {
    flex: 1 0 30%; /* Adjusts to three columns on larger screens */
  }
}

.bid-amount {
  color: white;
  width: 100%;
  padding: 20px;
  margin: 5px;
  border-radius: 50%;
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: rgb(22, 52, 86);
  box-shadow: 4px 2px 2px gray;
}

.precio {
  color: black;
  text-shadow: 0.1px 0px gray;
  font-size: 20px; 
  font-weight: bold;
  text-align: center;
}

.extra-info {
  font-size: 10px;
  display: inline-block;
  color: black;
  text-align: center;
  cursor: pointer;
  width: 100%;
  transition-duration: 0.1s;
  border-radius: 12px;
  text-decoration: none;
  margin-bottom: 10px;
}

.custom-button {
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 12px;
}
.custom-button:hover {
  background-color: #fde23045;
  color: white;
  border-radius: 40px;
}
.custom-button:active {
  background-color: #FFDB00;
  box-shadow: 0 0px #999;
  transform: translateX(1.5px) translateY(1.5px);
}
.custom-button:focus{
   background-color:gold;
}
</style>