<template>
  <button @click="onClick" class="custom-button">
    {{ message }}
  </button>
</template>

<script>
export default {
  name: "Button",
  props: {
    onClick: { type: Function, required: false },
    message: { type: String, required: true },
    customStyle: { type: Object, required: false }
  },
};
</script>

<style scoped>

button {
  border: none;
  outline: 0;
  display: inline-block;
  padding: 8px;
  color: white;
  background-color: Grey;
  text-align: center;
  cursor: pointer;
  width: 66%;
  transition-duration: 0.1s;
  border-radius: 9px;	
  box-shadow: 1.5px 3px #999;
  font-weight: bold;
  font-family: 'Hind', sans-serif;
}
.custom-button:hover {
  background-color: #FDE030;
  color: white;
}
.custom-button:active {
  background-color: #FFDB00;
  box-shadow: 0 0px #999;
  transform: translateX(1.5px) translateY(1.5px);
}
.custom-button:focus{
   background-color:gold;
}

</style>
