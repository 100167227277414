<template>
  <!--============= Footer Section Starts Here =============-->
  <footer class="bg_img padding-top oh" data-background="./assets/images/footer/footer-bg.jpg">
        <div class="footer-top-shape">
            <img src="../assets/images/footer/footer-top-shape.png" alt="css">
        </div>
        <div class="footer-top padding-bottom padding-top">
            <div class="container">
                <div class="row mb--60">
                    <div class="col-sm-6 col-lg-3">
                        <div class="footer-widget widget-links">
                            <h5 class="title">Juegos</h5>
                            <ul class="links-list">
                                <li>
                                    <router-link to="/games">Juegos en curso</router-link>
                                </li>
                                <li>
                                    <router-link to="games/won_games">Productos ganados</router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-3">
                        <div class="footer-widget widget-links">
                            <h5 class="title">Nosotros</h5>
                            <ul class="links-list">
                                <li>
                                    <router-link to="faq">Acerca de Pami</router-link>
                                </li>
                                <li>
                                    <a href="mailto:agustin@pami.cl">Contacto</a>
                                </li>
                                <li>
                                    <a href="mailto:agustin@pami.cl">Vende con nosotros</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-3">
                        <div class="footer-widget widget-links">
                            <h5 class="title">Queremos ayudarte</h5>
                            <ul class="links-list">
                                <li>
                                    <a href="https://wa.me/569">Contáctanos</a>
                                </li>
                                <li>
                                    <router-link to="faq">Preguntas Frecuentes</router-link>
                                </li>

                            </ul>
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-3">
                        <div class="footer-widget widget-follow">
                            <h5 class="title">Síguenos</h5>
                            <ul class="links-list">
                                <li>
                                    <a href="mailto:agustin@pami.cl"><i class="fas fa-envelope-open-text"></i>agustin@pami.cl</a>
                                </li>
                                <li>
                                    <a href="https://www.google.cl/maps/place/Waterloo+676,+Las+Condes,+Regi%C3%B3n+Metropolitana/@-33.4002501,-70.5441835,17z/data=!3m1!4b1!4m5!3m4!1s0x9662cebb9990eb7b:0x13d77789ebbe4300!8m2!3d-33.4002501!4d-70.5419948" target=”_blank”><i class="fas fa-location-arrow"></i>Waterloo 676, Las Condes</a>
                                </li>
                            </ul>
                            <ul class="social-icons">
                                <li>
                                    <a href="#0"><i class="fab fa-instagram"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-bottom">
            <div class="container">
                <div class="copyright-area">
                    <div class="footer-bottom-wrapper">
                        <div class="logo">
                            <a href="#0"><img src="../assets/images/logo/footer-logo.png" alt="logo"
                                width="41px"
                                height="33px"></a>
                        </div>
                        <ul class="gateway-area">
                            <li>
                                <a href="#0"><img src="../assets/images/footer/mastercard.png" alt="Mastercard"></a>
                            </li>
                        </ul>
                        <div class="copyright"><p>&copy; Todos los derechos reservados 2024 | <a>Pami</a></p></div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    <!--============= Footer Section Ends Here =============-->
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Button from './packs/utils/Button.vue';
export default {
  name: "NavBar",
  components: {Button},
  data() {
    return {
      titulo: "Lista de Postres",
      displayModal: '',
      email: '',
      password: '',
      windowTop: 0,
      activeMenu: false,
      submenuDisplay: {
        account: true,
      }
    }
  },
  computed: {
    ...mapGetters('sessionManager', ['getUserData', 'isLoggedIn', 'isAdmin']),
    activeClass() {
      return this.windowTop >= 10;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    ...mapActions('sessionManager', ['logoutUser', 'loginUser']),
    onScroll(e) {
      this.windowTop = window.top.scrollY;
    },
    openLogIn() {
      this.displayModal = 'block';
    },
    logoutUser() {
    // Realiza las acciones necesarias al cerrar sesión
    this.$store.dispatch('sessionManager/logoutUser')
      .then(() => {
        this.activeMenu = false; // Cierra el menú desplegable al hacer clic en "Salir"
      })
      .catch(error => {
        console.error('Error al cerrar sesión:', error);
      });
  },
  closeDropdown() {
    this.activeMenu = false;
  },
    logIn() {
      this.loginUser({user: {email: this.email, password: this.password }})
        .then(() => {
          this.displayModal = 'none';
        });
    },
    closeLogIn() {
      this.displayModal = 'none';
    },
  }
}

</script>

<style scoped>
footer {
    position: relative;
}

.footer-top-shape {
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    min-width: 1400px;
    width: 100%;
}

@media (max-width: 767px) {
    .footer-top-shape {
        min-width: 570px;
    }
}

.footer-top-shape img {
    width: 100%;
}

.newslater-area {
    position: relative;
    z-index: 1;
    padding: 20px 0;
    border-radius: 20px;
    background-color: white;
    box-shadow: 0.487px 3.97px 81px 0px rgba(40, 58, 140, 0.46);
}

.newslater-area .newslater-thumb {
    padding: 15px;
    display: none;
}

.newslater-area .newslater-thumb img {
    position: absolute;
    max-width: 100%;
    max-height: 100%;
    top: 50%;
    left: 30px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

@media (min-width: 992px) {
    .newslater-area .newslater-thumb {
        display: block;
    }
}

.newslater-area .newslater-content {
    width: 100%;
    padding: 15px;
}

@media (min-width: 576px) {
    .newslater-area {
        padding: 25px;
    }
}

@media (min-width: 992px) {
    .newslater-area {
        padding: 55px 45px 45px;
    }

    .newslater-area .newslater-content, .newslater-area .newslater-thumb {
        width: 50%;
    }
}

.subscribe-form {
    position: relative;
}

.subscribe-form input {
    width: 100%;
    padding: 0 150px 0 25px;
    height: 60px;
    background: transparent;
    border: 1px solid #d9d9dd;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
}

.subscribe-form button {
    position: absolute;
    right: 0;
    top: 0;
}

@media (max-width: 575px) {
    .subscribe-form input {
        height: 50px;
        padding: 0 20px;
    }

    .subscribe-form button {
        position: relative;
        margin-top: 20px;
    }
}

.mb--60 {
    margin-bottom: -60px;
}

.footer-widget {
    margin-bottom: 60px;
}

.footer-widget .title {
    color: #5d68ff;
    margin-bottom: 18px;
    font-weight: 500;
    font-size: 24px;
}

.footer-widget .links-list {
    margin-bottom: -14px;
    margin-bottom: 20px;
}

.footer-widget .links-list li {
    padding: 0;
}

.footer-widget .links-list li a {
    padding: 5px 0 4px;
    color: #5d68ff;
    font-weight: 400;
}

@media (max-width: 575px) {
    .footer-widget .links-list li a {
        font-size: 16px;
    }
}

.footer-widget .links-list li a i {
    margin-right: 35px;
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
    .footer-widget .links-list li a i {
        margin-right: 10px;
    }
}

.footer-widget .links-list li a:hover {
    color: #FDE030;
}

.copyright-area {
    border-top: 1px solid rgba(255, 255, 255, 0.302);
}

.footer-bottom-wrapper {
    justify-content: space-between;
    align-items: center;
    padding: 30px 0 0;
    margin-left: -15px;
    margin-right: -15px;
}

.footer-bottom-wrapper .logo, .footer-bottom-wrapper .gateway-area, .footer-bottom-wrapper .copyright {
    margin-bottom: 25px;
    padding-left: 15px;
    padding-right: 15px;
}

.footer-bottom-wrapper .logo a {
    display: block;
}

.footer-bottom-wrapper .logo img {
    width: 100%;
}

.footer-bottom-wrapper .gateway-area {
    margin: -8px;
    margin-bottom: 17px;
}

.footer-bottom-wrapper .gateway-area li {
    padding: 8px;
}

.footer-bottom-wrapper .copyright p {
    margin: 0 !important;
    color: rgba(0, 0, 0, 0.9);
}

@media (max-width: 991px) {
    .footer-bottom-wrapper .logo {
        width: 150px;
    }
}

.anime-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
}

.anime-wrapper div[class*="anime"] {
    position: absolute;
}

.anime-wrapper .anime-1 {
    top: 30px;
    left: calc(50% - 500px);
}

.anime-wrapper .anime-2 {
    top: 40px;
    left: calc(50% - 180px);
}

.anime-wrapper .anime-3 {
    top: 350px;
    left: calc(50% - 620px);
}

.anime-wrapper .anime-6 {
    top: 250px;
    left: calc(50% - 500px);
}

.anime-wrapper .anime-5 {
    top: 330px;
    left: calc(50% - 200px);
}

.anime-wrapper .anime-7 {
    top: 360px;
    left: calc(50% - 450px);
}

.social-icons {
    margin: -5px;
}

.social-icons li {
    padding: 5px;
}

.social-icons li a {
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    background: #ffffff;
    color: #52606d;
}

.social-icons li a:hover, .social-icons li a.active {
    color: #ffffff;
    background: #ee4730;
}

@media (max-width: 1199px) {
    .social-icons li a {
        width: 45px;
        height: 45px;
        line-height: 45px;
    }
}
</style>
